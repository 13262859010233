<template>
  <DatePicker
    v-if="props.type === 'date'"
    v-bind="$attrs"
    ref="datePickerRef"
    class="elv-date-picker"
    :bordered="false"
    :locale="locale === 'en' ? enUS : zhCN"
    popup-class-name="elv-picker-dropdown"
    :show-today="false"
    value-format="YYYY-MM-DD"
    :format="inputFormat"
    @openChange="onOpenChange"
  >
    <template #suffixIcon><SuffixIcon /></template>
  </DatePicker>
  <!-- :disabled-date="disabledDate" -->
  <RangePicker
    v-else
    ref="rangPickerRef"
    v-bind="$attrs"
    class="elv-picker-range"
    :locale="locale === 'en' ? enUS : zhCN"
    :bordered="false"
    :presets="rangePresets"
    value-format="YYYY-MM-DD"
    :input-format="inputFormat"
    popup-class-name="elv-picker-dropdown"
    @calendarChange="onCalendarChange"
    @openChange="onOpenChange"
  >
    <template #suffixIcon><SuffixIcon /></template>
    <template #separator>{{ rangePickerValue }}</template>
  </RangePicker>
  <!-- :disabled-date="disabledDate" -->
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import type { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import SuffixIcon from './components/SuffixIcon.vue'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { DatePicker, RangePicker } from 'ant-design-vue'
import { useEntityStore } from '@/stores/modules/entity'
import zhCN from 'ant-design-vue/es/date-picker/locale/zh_CN'
import enUS from 'ant-design-vue/es/date-picker/locale/en_US'

defineOptions({
  inheritAttrs: false
})

const props = defineProps({
  type: {
    type: String,
    default: 'range'
  },
  enableDisabled: {
    type: Boolean,
    default: true
  }
})

defineEmits(['update:modelValue'])

type RangeValue = [string, string] | [Dayjs, Dayjs]

dayjs.extend(utc)
dayjs.locale('en')
dayjs.extend(advancedFormat)
const attrs: any = useAttrs()
const { locale } = useI18n()
const entityStore = useEntityStore()
const instance: any = getCurrentInstance()

const dateTimes = ref()
const datePickerRef = ref()
const rangPickerRef = ref()
const pickerArrow = ref('rotate(0deg)')

const rangePresets = ref([
  {
    label: 'All Time',
    value: [dayjs(), dayjs().add(-1, 'd')]
  },
  { label: 'Last 30d', value: [dayjs().add(-30, 'd'), dayjs()] },
  { label: 'Last 7d', value: [dayjs().add(-7, 'd'), dayjs()] }
])

const inputFormat = computed(() => {
  let format = 'YYYY-MM-DD'
  switch (attrs.picker?.toLocaleLowerCase()) {
    case 'month':
      format = 'YYYY-MM'
      break
    case 'quarter':
      format = 'YYYY-[Q]Q'
      break
    case 'year':
      if (props.type === 'date') {
        format = 'YYYY'
      }
      break
    default:
      break
  }
  return format
})

const rangePickerValue = computed(() => {
  if (!attrs.value?.length) return ''
  const start = dayjs(attrs.value[0]).format(inputFormat.value)
  const end = dayjs(attrs.value[1]).format(inputFormat.value)
  return `${start} - ${end}`
})

const resizeInputWidth = () => {
  nextTick(() => {
    const input: any = datePickerRef.value?.$el?.querySelector('.ant-picker-input input')
    input.style.width = `${input.value.length + 1}ch`
  })
}
/**
 * @description: 展开/收起时间选择器
 * @param {boolean} status
 */
const onOpenChange = (status: boolean) => {
  console.log(3123123, status)
  pickerArrow.value = status ? 'rotate(-180deg)' : 'rotate(0deg)'
}

/**
 * @description: 日期选择器值改变
 * @param {RangeValue} val
 */
const onCalendarChange = (val: RangeValue) => {
  dateTimes.value = val
}

// eslint-disable-next-line no-unused-vars
const disabledDate = (current: Dayjs) => {
  if (props.type === 'date' || !props.enableDisabled || dateTimes.value === null) return false

  if ((!dateTimes.value || (dateTimes.value as any)?.length === 0) && attrs.value?.length === 0) {
    return false
  }

  const start = dateTimes.value ? dayjs((dateTimes.value as any)?.[0]) : dayjs((attrs.value as any)?.[0])
  if (attrs.picker === 'month') {
    const maxRange = 12 // 最大允许的月份范围
    const startMonth = start.startOf('month')
    const currentMonth = current.startOf('month')

    const diffMonths = currentMonth.diff(startMonth, 'months')
    return diffMonths < 0 || diffMonths >= maxRange
  }
  if (attrs.picker === 'quarter') {
    const maxQuarters = 8 // 最大允许的季度范围
    const startYear = start.year()
    const currentYear = current.year()
    const diffYears = currentYear - startYear
    if (diffYears < 0) {
      return true
    }
    const startQuarter = start.quarter()
    const currentQuarter = current.quarter()
    const diffQuarters = diffYears * 4 + currentQuarter - startQuarter
    return diffQuarters >= maxQuarters
  }
  if (attrs.picker === 'date') {
    const maxDays = 14 // 最大允许的日范围
    const diffDays = current.diff(start, 'days')
    return diffDays < 0 || diffDays >= maxDays
  }
  return false
}

/**
 * @description: 重置日期时间选择器
 */
const resetDateTime = () => {
  dateTimes.value = undefined
}

defineExpose({ ...instance.exposed, resetDateTime })

watch(locale, (val) => {
  dayjs.locale(val)
})

watchEffect(() => {
  rangePresets.value[0].value[0] = entityStore.entityDetail?.creationDate
    ? dayjs(entityStore.entityDetail?.creationDate)
    : dayjs.utc(entityStore.entityDetail?.createdAt)
})

watch(
  () => props.type,
  () => {
    nextTick(() => {
      const entries =
        props.type === 'date'
          ? Object.entries(datePickerRef.value.$.exposed)
          : Object.entries(rangPickerRef.value.$.exposed)
      for (const [key, value] of entries) {
        instance.exposed[key] = value
      }
    })
  },
  { immediate: true }
)

watch(
  () => attrs.value,
  () => {
    if (props.type === 'date') {
      nextTick(() => {
        resizeInputWidth()
      })
    }
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss">
.ant-picker-dropdown {
  &.elv-picker-dropdown {
    padding: 0;

    .ant-picker-range-arrow {
      display: none;
    }

    .ant-picker-panel-container {
      border-radius: 4px;
      border: 1px solid #e4e7eb;
      background: #fff;
      box-shadow:
        0px 0px 1px 0px rgba(0, 0, 0, 0.15),
        0px 4px 8px 0px rgba(0, 0, 0, 0.04),
        0px 8px 16px 0px rgba(0, 0, 0, 0.04);

      .ant-picker-panel-layout {
        padding: 8px;
        padding-bottom: 3px;
        display: flex;
        flex-direction: column-reverse;

        .ant-picker-panels {
          border-bottom: 1px solid #edf0f3;
        }

        .ant-picker-presets {
          flex-direction: row;
          width: 100%;
          height: 38px;
          box-sizing: border-box;

          ul {
            display: flex;
            height: 21px;
            padding: 8px 0;
            border-right: 0px;

            li {
              height: 21px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
              box-sizing: border-box;
              border-radius: 11px;
              border: 1px solid #d0d4d9;
              color: #636b75;
              font-family: 'Plus Jakarta Sans';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 13px;
              margin-right: 4px;

              &:first-of-type {
                width: 63px;
                margin-left: 0px;
              }

              + li {
                margin-top: 0px;
                width: 71px;
              }

              &:last-of-type {
                width: 61px;
                margin-right: 0px;
              }

              &:hover {
                background: #e6edff;
                border: 1px solid #5e85eb;
                color: #1753eb;
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .ant-picker-header {
      padding: 0 6px;
      border-bottom: 1px solid #edf0f3;

      button {
        line-height: 34px;
      }
    }

    .ant-picker-header-view {
      line-height: 34px;
      // width: 80px;
      // display: flex;
      // justify-content: space-between;
      // button:not(:first-child) {
      //   margin-inline-start: 0px;
      // }
      .ant-picker-month-btn {
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-weight: 700;
        // line-height: 14px;
        color: #1e2024;
      }

      .ant-picker-year-btn {
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-weight: 500;
        // line-height: 14px;
        color: #1e2024;
      }

      .ant-picker-decade-btn {
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-weight: 500;
        // line-height: 14px;
        color: #1e2024;
      }
    }

    .ant-picker-header-view:has(.ant-picker-month-btn) {
      .ant-picker-year-btn {
        color: #aaafb6;
      }
    }

    .ant-picker-date-panel {
      width: 184px;

      .ant-picker-content {
        width: 184px;

        th {
          width: 26px;
        }
      }
    }

    .ant-picker-panel:nth-of-type(2) {
      margin-left: 16px;
    }

    .ant-picker-date-panel .ant-picker-body {
      padding: 6px 0 8px 0;

      thead tr {
        th {
          font-family: 'Barlow';
          font-size: 11px;
          font-weight: 500;
          line-height: 11px;
          color: #aaafb6;
        }
      }

      .ant-picker-cell {
        // visibility: hidden;
        // &.ant-picker-cell-in-view {
        //   visibility: visible;
        // }
        padding: 0px;
        height: 28px;
        box-sizing: border-box;

        .ant-picker-cell-inner {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          height: 100%;
          box-sizing: border-box;
          border-radius: 0px !important;
        }

        &.ant-picker-cell-in-view {
          .ant-picker-cell-inner {
            color: #636b75;
          }

          &:not(.ant-picker-cell-range-start, .ant-picker-cell-range-end).ant-picker-cell-selected {
            .ant-picker-cell-inner {
              background: #fff;
              border-bottom: 1px solid #1343bf;
              color: #0e0f11;
              font-weight: 600;
            }
          }
        }

        &.ant-picker-cell-today .ant-picker-cell-inner {
          &::before {
            border: none;
          }
        }

        &.ant-picker-cell-range-start,
        &.ant-picker-cell-range-end,
        &.ant-picker-cell-in-range {
          border-bottom: 1px solid #1343bf;

          &::before {
            background-color: #fff;
          }

          .ant-picker-cell-inner {
            color: #0e0f11;
            font-weight: 600;
            background-color: #fff;
          }
        }

        &.ant-picker-cell-range-hover-end,
        &.ant-picker-cell-range-hover-start {
          &::after {
            inset-inline-end: 1px !important;
            inset-inline-start: 1px !important;
          }
        }

        &.ant-picker-cell-in-range {
          &.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
          &.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
            inset-inline-end: 0px;
            inset-inline-start: 0px;
          }
        }
      }
    }

    .ant-picker-year-panel,
    .ant-picker-month-panel,
    .ant-picker-quarter-panel {
      width: 184px;

      .ant-picker-body {
        padding: 0px;
        box-sizing: border-box;

        .ant-picker-content {
          height: fit-content;
        }

        .ant-picker-cell-inner {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          box-sizing: border-box;
          color: #d9dbdf;
          border-radius: 0px !important;
          width: 60px;
          height: 53px;
          padding: 0px;
        }

        .ant-picker-cell {
          padding: 0px;
          height: 53px;

          &::before {
            height: 48px;
          }

          &.ant-picker-cell-in-view {
            .ant-picker-cell-inner {
              color: #636b75;
            }

            &:not(.ant-picker-cell-range-start, .ant-picker-cell-range-end).ant-picker-cell-selected {
              .ant-picker-cell-inner {
                background: #fff;
                border-bottom: 1px solid #1343bf;
                color: #0e0f11;
                font-weight: 600;
              }
            }
          }

          &.ant-picker-cell-range-start,
          &.ant-picker-cell-range-end,
          &.ant-picker-cell-in-range {
            border-bottom: 1px solid #1343bf;

            &::before {
              background-color: #fff;
            }

            .ant-picker-cell-inner {
              color: #0e0f11;
              font-weight: 600;
              background-color: #fff;
            }
          }

          &.ant-picker-cell-range-hover-end,
          &.ant-picker-cell-range-hover-start,
          &.ant-picker-cell-range-hover {
            &::after {
              height: 48px;
              inset-inline-end: 1px !important;
              inset-inline-start: 1px !important;
            }
          }

          &.ant-picker-cell-in-range {
            &.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after,
            &.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
              height: 48px;
              inset-inline-end: 0px;
              inset-inline-start: 0px;
            }
          }
        }
      }
    }
  }
}

.ant-picker {
  padding: 0px;

  &.elv-picker-range {
    padding: 0px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    height: 15px;
    min-width: 30px;
    border-color: #dde1e6;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 4px;

    .ant-picker-input {
      display: none;
    }

    .ant-picker-range-separator {
      padding: 0px;
      cursor: pointer;
    }

    &:hover {
      border-color: #5e85eb;
    }

    &.ant-picker-focused {
      border-color: #5e85eb;
    }

    .ant-picker-active-bar {
      height: 0px;
    }

    .ant-picker-clear {
      inset-inline-end: 0px;
    }

    .ant-picker-input > input {
      height: 28px;
      color: #1e2024;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }

    .ant-picker-suffix {
      svg {
        width: 16px;
        height: 16px;
        fill: #838d95;
        transition: transform 0.3s ease-in-out;
        transform: v-bind('pickerArrow');
      }
    }
  }

  &.elv-date-picker {
    .ant-picker-input {
      .ant-picker-suffix {
        svg {
          width: 16px;
          height: 16px;
          fill: #838d95;
          transition: transform 0.3s ease-in-out;
          transform: v-bind('pickerArrow');
        }
      }
    }
  }
}
</style>
